<template>
  <div :class="{'my_test_paper':true ,'sel_correct': userInfo.correct_type == 2 ? true : false}">
    <div class="back_wrap">
      <div>
        <img src="@/static/back.png"
             @click="back"
             alt="">
      </div>
      <!--   <div>
        <a-dropdown>
          <span class="ant-dropdown-link"
                @click.prevent>
            {{subjectList[subjectIndex]?subjectList[subjectIndex].subject_name : ''}}
            <caret-down-outlined />
          </span>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="(item,index) in subjectList"
                           @click.prevent="subjectChange(index)"
                           :key="item.subject_id">
                {{item.subject_name}}
              </a-menu-item>

            </a-menu>
          </template>
        </a-dropdown>
      </div> -->
      <dropDown :list='subjectList'
                :index='subjectIndex'
                attribute_name='subject_name'
                @changeMenu='subjectChange'
                background='#3e8ef3' />
    </div>
    <div class="subjectabs">
      <div :class="{item:true,active:idx == item.id}"
           v-for="(item,index) in statusList"
           @click="checkStatus(item.id)"
           :key="index" style="cursor: pointer;">
        {{item.label}}
      </div>
      <div class="search">
        <img src="@/static/baisearch.png"
             @click="showDialog"
             alt="">
      </div>
    </div>
    <div class="papers"
         v-if="idx != 5">
      <div class="paper_wrap"
           v-for="(item,index) in paperList"
           :key="index">
        <div class="paper">
          <div v-if="true">
            <div class="top">
              <div class="left">
                <div class="subject">
                  {{item.subject_name}}
                </div>
                <div class="templateID">ID:{{item.user_paper_id}}</div>
              </div>

              <div class="status"
                   :style="{'color':getColor(item)}">{{getValue(item)}}</div>
            </div>
            <div class="templatename">
              {{item.source_name ||item.module_name}}
            </div>

            <div class="score">
              <div style="display: flex;gap: 32rem;">
                <div style="flex: 1;">我的得分: <span style="color:#f2413a;font-weight: 600;">{{item.user_score}}</span></div>
                <div v-if="canShowRank(item)" style="display: flex;gap: 32rem;flex: 1.5;">
                  <!-- <div><span>排名：</span><span style="color:#FB9930;font-weight: 600;">{{item.rank}}</span></div> -->
                  <div v-if="parseFloat(item.user_score) != 0"><span style="color: #666;">打败了{{parseInt(item.percent)}}%人</span></div>
                </div>
              </div>
              <div style="display: flex;gap: 32rem;">
                <div v-if="canShowRank(item)" style="flex: 1;">
                  平均得分: <span style="color:#333;font-weight: 600;">{{item.avg_score}}</span>
                </div>
                <div style="flex: 1.5;">
                  总分: <span style="color:#333;font-weight: 600;">{{item.paper_score}}</span>
                </div>
              </div>
              <div class="comment">
                <span style="color: #999;">总评：</span>
                <span class="overflow-hidden" style="flex: 1 0 0;color:#333">
                  <label v-if="item.paper_comment && item.paper_comment.length > 36" @click="showCommentDetail(item)" style="color: #2196f3; float: right;clear: both;line-height: 30rem;cursor: pointer;">查看全部</label>
                  {{item.paper_comment?item.paper_comment:'无'}}
                </span>
              </div>
              <!-- <div class="comment">
                <span>总评: </span><span>{{item.paper_comment || '无'}}</span>
              </div> -->
            </div>
            <div class="btn_warp">
              <div class="btn"
                   @click="toPaperInfo(item)">
                答案解析
              </div>
            </div>
            <div class="time">
              {{item.paper_time}}
            </div>
          </div>
        </div>
      </div>
      <div class="paper_wrap"
           style="height:0"></div>
      <div class="paper_wrap"
           style="height:0"></div>

    </div>

    <!-- 未完成 -->
    <div class="papers"
         v-else>
      <div class="paper_wrap"
           v-for="(item,index) in paperList"
          
           :key="index">
        <div class="paper"
            >
          <div v-if="true">
            <div class="top">
              <div class="left">
                <div class="subject">
                  {{item.subject_name}}
                </div>
                <div class="templateID">ID:{{item.user_paper_id}}</div>
                <div class="status">{{getValue(item)}}</div>
              </div>
            </div>
            <div class="templatename">
              {{item.source_name||item.module_name}}
            </div>
            <div class="score">
            <div style="display: flex;gap: 32rem;">
                <div style="flex: 1;">我的得分: <span style="font-weight: 600;">—</span></div>
               
              </div>
              <div style="display: flex;gap: 32rem;">
                <div  style="flex: 1;">
                  平均得分: <span style="color:#333;font-weight: 600;">{{item.avg_score}}</span>
                </div>
                <div style="flex: 1.5;">
                  总分: <span style="color:#333;font-weight: 600;">{{item.paper_score}}</span>
                </div>
              </div>
              <div class="comment">
                <span style="color: #999;">总评：</span>
                <span class="overflow-hidden" style="flex: 1 0 0;color:#333">
                  <label v-if="item.paper_comment && item.paper_comment.length > 36" @click="showCommentDetail(item)" style="color: #2196f3; float: right;clear: both;line-height: 30rem;cursor: pointer;">查看全部</label>
                  {{item.paper_comment?item.paper_comment:'—'}}
                </span>
              </div>
            </div>
            <div class="btn_warp">
              <div class="btn"
                   @click="doPaper(item)">
                继续做卷
              </div>
            </div>
            <div class="time">
              {{item.paper_time}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormDialog ref="formDialogRef"
                @getData="getPaperList" />

    <a-modal v-model:visible="commentVisiable"
      title="总评"
      :bodyStyle="{padding: '40rem'}"
      :maskClosable="false"
      :modal='true'
      width="720rem"
      :closable="false"
      :centered="true"
      :footer="null">
      <span>{{ commentDetail?commentDetail:'无' }}</span>
      <div style="width: 100%;display: flex;justify-content: center;margin-top: 40rem;">
        <a-button @click="() => {commentVisiable=false;commentDetail='';}" type="text"><span style="color:#2196f3">关闭</span></a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import FormDialog from '@/views/components/COMMON_TOP_SEARCH/search.vue'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { getUserSubject } from '@/api/subject.js'
import { getMyPaperList } from '@/api/myPaper.js'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { CaretDownOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import dropDown from '@/views/components/dropdown/index.vue'

const route = useRoute()
const router = useRouter()
const formDialogRef = ref(null)

let idx = ref('')
let statusList = ref([
  { id: '', label: '所有试卷' },
  { id: 1, label: '未批改试卷' },
  { id: 3, label: '已批改试卷' },
  { id: 5, label: '未完成试卷' },
])
let keywords = ref('')
let subjectList = ref([])
let subjectIndex = ref(0)
let paperList = ref([])
let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
let dialogVisible = ref(false)
let store = useStore()
let commentVisiable = ref(false)
let commentDetail = ref('')

onMounted(() => {
  getSubject()
  window.sessionStorage.removeItem('images')
  window.sessionStorage.removeItem('analysisCard')
  if (window.localStorage.getItem('subjectIndex')) {
    subjectIndex.value = window.localStorage.getItem('subjectIndex');
  }
  if (window.localStorage.getItem('statusId')) {
    checkStatus(window.localStorage.getItem('statusId'))
  }
})

// 获取科目-待会看看怎么优化,看看怎么跟之前封装组件整合起来
const getSubject = async () => {
  const { data } = await getUserSubject()
  subjectList.value = data.list
  getPaperList()
}

const canShowRank = (row) => {
  return row.judge_status == 4 && row.source_id != 0
}

// 获取试卷列表
const getPaperList = async (keyword) => {
  keywords.value = keyword
  let params = {
    subject_id: subjectList.value[subjectIndex.value].subject_id,
    keywords: keyword,
    status: idx.value
  }
  const { data } = await getMyPaperList(params)
  // paperList.value = [...data.list, ...data.list, ...data.list, ...data.list, ...data.list]
  paperList.value = data.list
}

// 切换tab状态
const checkStatus = (id) => {
  window.localStorage.setItem('statusId', id)
  idx.value = id
  keywords.value = ''
  if (id == 5) {
    paperList.value = []
  }
  getPaperList()
}

// 点击试卷-跳转试卷详情
const toPaperInfo = (row) => {
  window.sessionStorage.removeItem('myTestPaperAnalsis')
  window.sessionStorage.removeItem('myTestPaperAnalsisIndex')
let  selfCorrectStatus = [101, 1, 102, 2, 103, 3, 10, 7, 107]
  if (row.correct_type == 2 && selfCorrectStatus.indexOf(row.judge_status) != -1) {
    // 用作答案解析判断是否为批改  为1的时候为查看 不做批改
    window.sessionStorage.removeItem('judge_status')
    if (row.judge_status != 1 && row.judge_status != -1) {
      window.sessionStorage.setItem('judge_status', 1)
    }
    window.sessionStorage.setItem('selfCorrectId', row.user_paper_id)
    router.push('/selfCorrect?&user_paper_id=' + row.user_paper_id + "&isCheck=" + row.judge_status)
    return
  }
  router.push('/analysis?user_paper_id=' + row.user_paper_id)
}

// 科目切换
const subjectChange = (index) => {
  window.localStorage.setItem('subjectIndex', index)
  subjectIndex.value = index
  getPaperList()
}

// tab不同选中状态显示对应不同内容
const getValue = (item) => {
  if (item.judge_status == 1 || item.judge_status == -1) {
    return '未批改'
  }
  if (item.judge_status == 2 || item.judge_status == 3) {
    return '正在批改'
  }
  let overArr = [4, 5, 6]
  if (overArr.indexOf(item.judge_status) != -1) {
    return '批改完成'
  }
}
// 点击继续做卷
const doPaper = (item) => {
  window.sessionStorage.removeItem('images')
  window.sessionStorage.removeItem('testpaperCardIndex')
  window.sessionStorage.removeItem('paper')
  router.push('/doPaper?user_paper_id=' + `${item.user_paper_id}`)
}

const back = () => {
  router.go(-1)
}

// 点击查询按钮
const showDialog = () => {
  formDialogRef.value.setData(true, keywords.value)
  // this.$refs.formDialogRef.dialogVisible = true
}
// 不同状态显示不同颜色
const getColor = (item) => {
  if (item.judge_status == 1 || item.judge_status == -1) {
    return '#FF6147'
  }

  if (item.judge_status == 2 || item.judge_status == 3) {
    return '#30D0F5'
  }

  let overArr = [4, 5, 6]
  if (overArr.indexOf(item.judge_status) != -1) {
    return '#0EC3B5'
  }
}

const showCommentDetail = (row) => {
  commentDetail.value = row.paper_comment
  commentVisiable.value = true
}
</script>

<style lang="scss" scoped>
.sel_correct {
  background-image: url("../../static/allBackg/bg-自评试卷.png") !important;
}
.my_test_paper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-image: url("../../static/allBackg/bg-我的试卷.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .subjectabs {
    width: 1680rem;
    height: 80rem;
    color: #e5e5e5;
    font-weight: 500;
    font-size: 32rem;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    margin-top: 30rem;
    border-radius: 67rem;
    background: #3e8ef3;
    .item {
      // z-index: 1999;
      margin-right: 120rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .search {
      cursor: pointer;
      position: absolute;
      right: 40rem;
      display: flex;
      align-items: center;
      top: 22rem;
      img {
        width: 36rem;
        height: 36rem;
      }
    }
  }
}

.active {
  color: #ffffff !important;
  font-weight: bold;
}

.papers {
  display: flex;
  flex-wrap: wrap;
  // background-color: #1955e3;
  width: calc(100vw - 110rem);
  height: calc(100vh - 250rem);
  margin: 10rem 0 0 55rem;
  padding: 0 35rem;
  overflow-y: auto;
  align-content: flex-start;
  .paper_wrap {
    width: 540rem;
    height: 396rem;
    padding: 0 5px 10px;
    margin: 0 auto;
  }
  .paper {
    position: relative;
    height: 100%;
    padding: 16rem 20rem;
    margin: 30rem 0rem 0 0;
    background-color: #ffffff;
    border: 10rem solid #83e7fe;
    border-radius: 20rem;
    flex-shrink: 0;
    align-items: flex-start;
    .top {
      position: relative;
      height: 32rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 300rem;
        display: flex;
        align-items: center;
      }
      .subject,
      .templateID {
        line-height: 30rem;
        padding: 2rem 14rem;
        font-size: 20rem;
        font-weight: 400;
      }
      .subject {
        background: rgba(48, 208, 245, 0.1);
        color: #30d0f5;
        border-radius: 24rem;
      }
      .templateID {
        color: #999;
      }
      .status {
        margin-right: 10rem;
        font-weight: bold;
        color: #f2413a;
        line-height: 30rem;
        font-size: 20rem;
      }
    }
    .templatename {
      height: 36rem;
      line-height: 36rem;
      margin: 12rem 0 8rem 0;
      font-size: 24rem;
      font-weight: 600;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    .score {
      // text-indent: 16rem;
      display: flex;
      line-height: 30rem;
      font-size: 20rem;
      font-weight: 400;
      color: #999999;
      flex-direction: column;
      span {
        // flex: 1;
      }
    }
    .comment {
      display: flex;
      flex-wrap: wrap;
      align-self: stretch;
      margin: 8rem 0 18rem;
      // margin-top: 8rem;
      // margin-bottom: 18rem;
      font-size: 20rem;
      font-weight: 400;
      color: #666666;
      line-height: 30rem;
    }
    .overflow-hidden {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // line-clamp: 2;
      // &:hover {
      //   display: block;
      //   overflow: auto;
      // }
    }
    .overflow-hidden::before {
      float: right;
      content: '';
      background-color: red;
      /* 减去尾随按钮高度  */
      height: calc(100% - 30rem);
    }
    .btn_warp {
      display: flex;
      justify-content: center;
      .btn {
        cursor: pointer;
        width: 150rem;
        height: 44rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20rem;
        font-weight: 400;
        color: #ffffff;
        background-color: #30d0f5;
        border-radius: 30rem;
        margin-bottom: 8rem;
      }
    }
    .time {
      display: flex;
      justify-content: center;
      font-size: 16rem;
      font-weight: 400;
      color: #999999;
    }
  }
}
.back_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30rem 40rem;
  img {
    width: 60rem;
    height: 60rem;
  }
  div {
    &:nth-child(2) {
      background-color: #3e8ef3;
      border-radius: 35rem;
      height: 60rem;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }
}
.ant-dropdown-link {
  font-size: 31rem;
  width: 200rem;
  height: 60rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>